import React, { useState, FC } from 'react'
import { Link } from 'gatsby'
import 'animate.css'
interface IProps {
  page: string
}
const Header: FC<IProps> = (props: IProps) => {
  const [opened, setOpened] = useState<boolean>(false)
  return (
    <header className='home-header d-flex container w-100'>
      <Link to='/'><img className='logo-header' src={require('../assets/images/thaco-logo-white.svg')} alt='' /></Link>
      <div className='d-none d-md-flex' style={{ alignItems: 'center' }}>
        <Link to='/' className={`${props.page === 'index' ? 'active' : ''} head-link mr-3 px-2`}>Home</Link>
        <Link to='/submission' className={`${props.page === 'submission' ? 'active' : ''} head-link mr-3 px-2`}>Submission</Link>
        <Link to='/schedule' className={`${props.page === 'schedule' ? 'active' : ''} head-link mr-3 px-2`}>Schedule</Link>
        <Link to='/about' className={`${props.page === 'about' ? 'active' : ''} head-link mr-3 px-2`}>About</Link>
      </div>
      <div className='d-md-none d-flex' style={{ alignItems: 'center' }}>
        <button onClick={() => setOpened(true)}
          className='btn btn-icon' >
          <span className='text-white material-icons'>
            menu
            </span>
        </button>
      </div>
      {opened &&
        <div style={{ animationDuration: '750ms' }} className={`animate__animated ${opened ? 'animate__fadeInDown' : 'animate__fadeOutUp'} nav-menu`} >
          <button onClick={() => setOpened(false)}
            className='btn btn-icon' style={{ position: 'absolute', right: 12, top: 32 }}>
            <span className='text-white material-icons'>
              close
            </span>
          </button>
          <div className='d-flex menu-container'>
            <Link to='/' className={`${props.page === 'index' ? 'active' : ''} head-link mb-3`}>Home</Link>
            <Link to='/submission' className={`${props.page === 'submission' ? 'active' : ''} head-link mb-3`}>Submission</Link>
            <Link to='/schedule' className={`${props.page === 'schedule' ? 'active' : ''} head-link mb-3`}>Schedule</Link>
            <Link to='/about' className={`${props.page === 'about' ? 'active' : ''} head-link `}>About</Link>
          </div>
        </div>
      }
    </header>
  )
}

export default Header