import React from "react"
import { Link } from "gatsby"
export const Footer = props => (
  <section className="container pb-5 pt-5 footer text-white" {...props}>
    <div
      className="d-flex text-center"
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        className="mb-2"
        width="40px"
        src={require("../assets/images/thaco-logo-white-no-text.png")}
        alt=""
      />
      <span className="monospace">Thailand Computing Olympiad 2020</span>
      <div className="footer-links mt-3 mb-3">
        <Link to="/contact" className="mr-3">
          Contact Us
        </Link>{" "}
        <span style={{ opacity: 0.6 }}>|</span>
        <Link to="/sponsors" className="ml-3 mr-3">
          Event Sponsorship
        </Link>{" "}
        <span style={{ opacity: 0.6 }}>|</span>
        <Link to="/about" className="ml-3">
          About
        </Link>
      </div>
      <Link
        to="https://www.facebook.com/ThailandComputingOlympiad"
        style={{ borderBottom: 0 }}
      >
        <img
          height="24px"
          src={require("../assets/images/facebook.svg")}
          alt=""
        />
      </Link>
    </div>
  </section>
)
export default Footer
