import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "react-bootstrap/Button"

import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from "gatsby"
const IndexPage = () => (
  <Layout>
    <SEO title="THACO | Submission" name="submission" />
    <Header page="submission" />

    <section className="text-white" style={{ paddingTop: 150 }}>
      <div className="container text-center" style={{ maxWidth: 800 }}>
        <h2>
          <span className="bg-dark px-2 py-1">การแต่งโจทย์</span>
        </h2>
        <p className="sans text-left mb-0 mt-5">
          สำหรับการแต่งโจทย์ ท่านสามารถร่วมแต่งโจทย์กับเราได้
          โดยขอบเขตเนื้อหาของโจทย์จะต้องไม่เกิน{" "}
          <Link to="/scope" className="sans pb-0">
            ขอบเขตเนื้อหาที่ใช้ในการแข่งขันคอมพิวเตอร์โอลิมปิกระดับชาติ
          </Link>
          {} โจทย์แต่ละข้อที่จะมีโอกาสได้รับการคัดเลือก
          จะต้องไม่เป็นไปตามข้อห้ามข้อใดข้อหนึ่งต่อไปนี้
          <ol className="text-left">
            <li>จำนวน subtasks น้อยกว่า 3</li>
            <li>
              โจทย์ไม่ทำให้ผู้เข้าแข่งขันพัฒนา (เช่น โจทย์ที่พบเห็นได้โดยง่าย
              ผู้ที่มีประสบการณ์สามารถคิดออกได้ภายในไม่เกิน 1 นาที
              ขาดความคิดริเริ่มสร้างสรรค์ในโจทย์)
            </li>
            <li>
              ความยากของการเขียนโปรแกรมไม่สมเหตุสมผลต่อระยะเวลา (เช่น
              เขียนมากกว่า 150 บรรทัดต่อข้อ)
            </li>
            <li>โจทย์ใช้เนื้อหานอกขอบเขตที่กำหนดไว้</li>
            <li>
              testcase ไม่เหมาะสม เช่น ค่าไม่ตรงเงื่อนไขที่โจทย์บอก
              หรือวิธีการที่ไม่ควรผ่าน ตามเงื่อนไขของโจทย์
              สามารถผ่านได้โดยไม่ได้ตั้งใจ
            </li>
            <li>
              time limit ไม่เหมาะสม เช่นโจทย์ต้องการแยกระหว่าง O(n) กับ O(n log
              n) แต่ตั้ง time limit ให้ solution บางตัวที่เป็น O(n log n)
              ผ่านได้ หรือบางตัวที่เป็น O(n) กลับไม่ผ่าน
            </li>
            <li>input/output format ไม่ชัดเจน</li>
          </ol>
          ถึงแม้ว่าจะไม่ขัดต่อข้อห้ามเหล่านี้แล้ว
          กรรมการคัดเลือกโจทย์มีสิทธิในการไม่อนุมัติโจทย์ได้เนื่องด้วยสาเหตุอื่น
          ทั้งนี้ ขึ้นอยู่กับดุลยพินิจของคณะกรรมการคัดเลือกโจทย์ โจทย์ทุกข้อ
          ถือเป็นกรรมสิทธิ์ของผู้แต่งโจทย์
          คณะกรรมการคัดเลือกโจทย์ไม่สามารถอ้างความเป็นเจ้าของได้ อย่างไรก็ตาม
          หลังมีการส่งโจทย์เข้ามาในระบบแล้ว
          เราจะไม่รับประกันว่าโจทย์ที่ไม่ได้ใช้จะไม่ถูกเผยแพร่หลังการแข่งขัน
          แต่เราจะรับประกันว่าโจทย์ทุกข้อที่ส่งเข้ามา
          จะไม่มีบุคคลอื่นนอกคณะกรรมการคัดเลือกโจทย์ ที่สามารถมองเห็นได้
          ก่อนเริ่มการแข่งขัน
        </p>
      </div>
    </section>
    <section className="text-white container py-5" style={{ maxWidth: 800 }}>
      <p>
        <h4>ขั้นตอนการแต่งโจทย์</h4>
        <p>
          <ul className="text-left">
            <li>เตรียมแนวคิดหลักของโจทย์ รวมถึงวิธีการที่คาดว่าจะเป็นเฉลย</li>
            <li>
              ส่งแนวคิดโจทย์ รวมถึงข้อจำกัด และรายละเอียดปัญหาย่อย
              ทางช่องทางด้านล่าง
            </li>
            <li>
              หลังจากส่งแล้ว เราจะทำการตรวจสอบข้อมูลที่คุณส่งมา
              แล้วเชิญคุณเข้าสู่ Discord Server ของผู้แต่งโจทย์
            </li>
            <li>
              เราจะประกาศโจทย์ที่ได้รับคัดเลือกเป็น Shortlisted Problems
              ภายในวันที่ 26 ตุลาคม
            </li>
            <li>
              หลังจากนั้นจะมีระยะเวลาให้เตรียมข้อมูลทดสอบ
              (อาจมีการเปลี่ยนแปลงภายหลัง:
              อาจมีการกำหนดให้เป็นหน้าที่ของคณะกรรมการคัดเลือกโจทย์เอง)
            </li>
            <li>กระบวนการหลังจากนี้ขึ้นอยู่กับการจัดการในช่วงเดือนพฤศจิกายน</li>
          </ul>
        </p>
      </p>
      <div className="text-center mt-4">
        <h3 className="text-rainbow">ส่งโจทย์ ได้ที่</h3>
        <br />
        <a
          href="mailto:submission@thaco.tech"
          className="sans"
          style={{ fontSize: "1.2em" }}
        >
          submission@thaco.tech
        </a>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default IndexPage
